<div class="side-nav side-nav-opend" id="side-nav" [ngClass]="isBusy > 0 ? 'wrapper-disabled' : ''">
    <ng-container *ngIf="!contextService.isSysAdmin();else isSysAdmin">
        <div class="select-company">
            <ng-template [ngIf]="showCompanyMenu">
                <div>
                    <ng-container *ngIf="userCompanies.length > 1; else notClickable">
                        <ng-container *ngIf="companyLocationName == 'Loading ...'; else notLoading">
                            <div class="d-flex menu-actions" [matMenuTriggerFor]="companies" data-bs-toggle="tooltip"
                                data-bs-placement="top" title="{{ companyLocationName }}">
                                <button class="select-company-button">
                                    {{ companyLocationName }}
                                    <span *ngIf="
                                        ((notificationsService.totalCompanyNotificationCount$
                                            | async) ??
                                            0) -
                                            ((notificationsService.notificationsCount$
                                                | async) || 0) !=
                                        0
                                    " class="has-notification"></span>
                                </button>
                            </div>
                        </ng-container>
                        <ng-template #notLoading>
                            <div class="d-flex menu-actions" [matMenuTriggerFor]="companies" data-bs-toggle="tooltip"
                                data-bs-placement="top" title="{{ companyLocationName }}">

                                <button class="select-company-button" id="select-company-button">
                                    <span class="select-company-image">
                                        <img class="select-company-icon" [src]="
                                        (contextService.companyLogoId$ | async)
                                            ? (imageSrc | async)
                                            : '../../../../../assets/images/select-company-icon.svg'
                                    " />
                                        <span *ngIf="!(shared.menuOpend$ |async) &&
                                            ((notificationsService.totalCompanyNotificationCount$
                                                | async) ??
                                                0) -
                                                ((notificationsService.notificationsCount$
                                                    | async) || 0) !=
                                            0
                                        " class="has-notification"></span>
                                    </span>
                                    {{ companyLocationName }}
                                    <span *ngIf="
                                        ((notificationsService.totalCompanyNotificationCount$
                                            | async) ??
                                            0) -
                                            ((notificationsService.notificationsCount$
                                                | async) || 0) !=
                                        0
                                    " class="has-notification"></span>
                                </button>
                            </div>
                            <span class="tooltip" id="tooltip" [ngbPopover]="welcome_tooltip_1"
                                #popover1Trigger="ngbPopover" placement="end" [autoClose]="false"></span>
                        </ng-template>
                    </ng-container>
                    <ng-template #notClickable>
                        <ng-container *ngIf="companyLocationName == 'Loading ...'; else notLoading">
                            <div class="d-flex" data-bs-toggle="tooltip" data-bs-placement="top"
                                title="{{ companyLocationName }}">
                                <button class="select-company-button cursor-default"
                                    [class.lodaing-company]="companyLocationName == 'Loading ...'">
                                    {{ companyLocationName }}
                                </button>
                            </div>
                        </ng-container>
                        <ng-template #notLoading>
                            <div class="d-flex" data-bs-toggle="tooltip" data-bs-placement="top"
                                title="{{ companyLocationName }}">
                                <button class="select-company-button">
                                    <span class="select-company-image">
                                        <img class="select-company-icon" [src]="
                                            (contextService.companyLogoId$ | async)
                                                ? (imageSrc | async)
                                                : '../../../../../assets/images/select-company-icon.svg'
                                        " />
                                        <span *ngIf="!(shared.menuOpend$ |async) &&
                                            ((notificationsService.totalCompanyNotificationCount$
                                                | async) ??
                                                0) -
                                                ((notificationsService.notificationsCount$
                                                    | async) || 0) !=
                                            0
                                        " class="has-notification"></span>
                                    </span>
                                    {{ companyLocationName }}
                                    <span *ngIf="
                                        ((notificationsService.totalCompanyNotificationCount$
                                            | async) ??
                                            0) -
                                            ((notificationsService.notificationsCount$
                                                | async) || 0) !=
                                        0
                                    " class="has-notification"></span>
                                </button>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
                <mat-menu #companies="matMenu" class="{{ opened ? 'list-opend' : 'list-closed' }}">
                    <div class="select-company-title heading-6">
                        {{ "COMPANIES" | translate }}
                    </div>
                    <ng-container *ngFor="let company of contextService.employeeCompanies$ |async">
                        <button mat-menu-item (click)="selectCompany(company)" class="company-item {{
                                    company.id ==
                                    (contextService.companyId$ | async)
                                        ? 'active-company'
                                        : ''
                                }}">
                            <span class="company-name"> {{ company.name }}</span>
                            <a *ngIf="company.numberOfNotifications > 0" class="number-of-notifications"
                                style="right: 1.5rem">
                                <span class="helper-text-1">{{
                                    company.numberOfNotifications > 10
                                    ? "10+"
                                    : company.numberOfNotifications
                                    }}</span>
                            </a>
                        </button>
                    </ng-container>
                    <span class="tooltip" id="tooltip" [ngbPopover]="welcome_tooltip_2" #popover2Trigger="ngbPopover"
                        placement="end" [autoClose]="'outside'"></span>
                </mat-menu>
            </ng-template>
        </div>
    </ng-container>
    <ng-template #isSysAdmin>
        <div class="select-company-title heading-6">
            <ng-container *ngIf="opened;else notOpened">
                {{ "SYSTEM_ADMIN" | translate }}
            </ng-container>
            <ng-template #notOpened>
                <span style="text-align: center;display: block;">
                    SA
                </span>
            </ng-template>

        </div>
    </ng-template>

    <nav class="nav">
        <ul class="first-nav flex-column d-flex align-self-stretch">
            <ng-container *ngFor="let item of sideBarList">
                <!-- <div> -->
                <ng-container *ngIf="item.show">
                    <li class="nav-item" routerLinkActive="active-main" class="{{ item.class }}"
                        (click)="openSubMenu(item)" [class.menu]="item.subMenu"
                        [class.menu-open]="item.subMenu && item.openSubMenu" data-bs-toggle="tooltip"
                        data-bs-placement="top" title="{{ item.title | translate }}"
                        [class.active-main]="item.subMenu && item.openSubMenu">
                        <!-- <img src="/assets/images/{{item.icon}}"> -->
                        <img class="list-icon" src="/assets/images/{{ item.icon }}"
                            routerLink="{{getParentRouterLink(item)}}" />
                        <a class="nav-link main-nav-link" [id]="item.id" routerLink="{{getParentRouterLink(item)}}"
                            href="javascript:void(0)">
                            <span> {{item.title | translate }}</span>
                        </a>
                    </li>
                    <div *ngIf="item.subMenu && item.openSubMenu" class="sub-menu" [class.sub-menu-container]="
                            item.subMenu && item.openSubMenu
                        ">
                        <ul class="flex-column">
                            <ng-container *ngFor="let sub of item.subMenu">
                                <ng-container *ngIf="sub.show">
                                    <li class="nav-item" class="{{ sub.class }}" routerLinkActive="active">
                                        <a class="sub-nav d-flex align-items-center" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="{{ sub.title | translate }}"
                                            [routerLink]="sub.routerLink">
                                            <img class="list-icon" src="/assets/images/{{
                                                    sub.icon
                                                }}" />
                                            <span class="nav-link">{{
                                                sub.title | translate
                                                }}</span>
                                        </a>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </div>
                </ng-container>
            </ng-container>
        </ul>
        <div class="last-icon fixed-bottom">
            <!-- <li class="nav-item last-item">
                <img src="/assets/images/settings-icon.svg"><a class="nav-link">{{"SETTINGS" |translate}}</a>
            </li> -->
            <li class="nav-item" (click)="collapseAll()" data-bs-toggle="tooltip" data-bs-placement="top"
                title="{{ 'COLLAPSE_ALL' | translate }}">
                <img [src]="
                        opened
                            ? '/assets/images/collapse-all.svg'
                            : '/assets/images/open-sidebar.svg'
                    " /><a class="nav-link"><span>{{ "COLLAPSE_ALL" | translate }}</span></a>
            </li>
            <li class="nav-item" *ngIf="!contextService.isSysAdmin() && showSettings" data-bs-toggle="tooltip"
                data-bs-placement="top" title="{{ 'SETTINGS' | translate }}" routerLinkActive="active-main"
                (click)="closeAllSubMenu();">
                <img src="/assets/images/settings-icon.svg" />
                <a class="nav-link" [routerLink]="onSettingsClick()">
                    <span>{{"SETTINGS" | translate }}</span>
                </a>
            </li>

        </div>
    </nav>
</div>

<ng-template #welcome_tooltip_1>
    <div class="tooltip-body">
        <div class="heading-7 mb-2">
            {{"YOUR_CURRENT_COMPANY" |translate}}: {{companyLocationName}}
        </div>
        <div class="helper-text-1" style="padding-right: 5px;">
            {{"SELECT_COMPANY_TOOLTIP_HELP_DESC" |translate}}
        </div>
        <div class="row mt-4 d-flex justify-content-end" style="align-items: center;gap: 1rem;padding-right: 1rem;">
            <div class="dismiss" (click)="popover1Trigger?.close()">
                {{"DISMISS" |translate}}
            </div>
            <button type="button" class="secondary-button-large" (click)="next()">
                {{ "NEXT" | translate }}
            </button>
        </div>
    </div>
</ng-template>
<ng-template #welcome_tooltip_2>
    <div class="tooltip-body">
        <div class="heading-7 mb-2">
            {{"CHANGE_YOUR_COMPANY" |translate}}: {{companyLocationName}}
        </div>
        <div class="helper-text-1" style="padding-right: 5px;">
            {{"TO_SELECT_A_DIFFERENT_COMPANY_SIMPLY_CLICK_ON_THE_COMPANY_LOGO_FROM_THE_LIST" |translate}}
        </div>
        <div class="mt-4 d-flex justify-content-end">
            <button type="button" class="secondary-button-large" (click)="finish()">
                {{ "FINISH" | translate }}
            </button>
        </div>
    </div>
</ng-template>